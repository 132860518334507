import * as Sentry from '@sentry/react';
import { rewriteFramesIntegration } from '@sentry/integrations';

import { SENTRY_DSN, SENTRY_RELEASE, APP_ENVIRONMENT } from './src/config';

Sentry.init({
    dsn: SENTRY_DSN ?? undefined,
    environment: APP_ENVIRONMENT,
    release: SENTRY_RELEASE ?? undefined,

    integrations: [
        Sentry.browserTracingIntegration(),
        rewriteFramesIntegration({
            // Turn `<origin>/<path>/...` into `app:///browser/...`
            iteratee: (frame) => {
                try {
                    const { origin } = new URL(frame.filename as string);
                    frame.filename = frame.filename?.replace(origin, 'app:///browser');
                } catch (err) {
                    // Filename wasn't a properly formed URL, so there's nothing we can do
                }

                if (frame.filename) {
                    if (
                        frame.filename.match(
                            /^app:\/\/\/browser\/(main-|main-app-|polyfills-|webpack-|framework-|framework\.)[0-9a-f]+\.js$/
                        )
                    ) {
                        // We don't care about these frames. It's Gatsby.js internal code.
                        frame.in_app = false;
                    }

                    if (frame.filename.match(/^app:\/\/\/browser\/~partytown\/.*\.js$/)) {
                        // We don't care about these frames. It's Gatsby.js internal code.
                        frame.in_app = false;
                    }

                    if (frame.filename.match(/^app:\/\/\/browser\/[0-9]+-[0-9a-f]+\.js$/)) {
                        // We don't care about these frames. It's seems it's Gatsby.js internal code.
                        frame.in_app = false;
                    }
                }

                return frame;
            }
        })
    ]
});
